import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const DateSelect = ({ events = [], onChange }) => {
  const [date, setDate] = useState(new Date());
  const [expanded, setExpanded] = useState(false);

  const format = () => {
    return moment(date).format('ddd, Do MMMM');
  };

  const custom_day = ({ date, view }) => {
    let date_lookup = moment(date).format('L');

    return view === 'month' && events[date_lookup] ? (
      <div className="dot"></div>
    ) : null;
  };

  const prev = () => {
    const yesterday = moment(date).subtract(1, 'days').toDate();
    setDate(yesterday);
    onChange && onChange(yesterday);
  };

  const next = () => {
    const tomorrow = moment(date).add(1, 'days').toDate();
    setDate(tomorrow);
    onChange && onChange(tomorrow);
  };

  const toggle = () => {
    setExpanded(!expanded);
  };

  const select = (d) => {
    setDate(d);
    setExpanded(false);
    onChange(d);
  };

  const get_day = () => {
    let date_lookup = moment(date).format('L');
    return events[date_lookup] ? events[date_lookup] + ' - ' : '';
  };

  return (
    <div className="date-selector">
      <div className="date-select">
        <FontAwesomeIcon
          className="date-select-prev"
          icon={faChevronLeft}
          color="#444"
          onClick={prev}
        />
        <span className="date-selected" onClick={toggle}>
          <strong>{get_day()}</strong> {format(date)}
        </span>
        <FontAwesomeIcon
          className="date-select-next"
          icon={faChevronRight}
          color="#444"
          onClick={next}
        />
      </div>
      {expanded && (
        <Calendar
          onChange={select}
          value={date}
          calendarType="US"
          tileContent={custom_day}
        />
      )}
    </div>
  );
};

export default DateSelect;
