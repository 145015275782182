import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ReactPlayer from 'react-player/youtube';

const Exercise = ({ exercise, links, expand = false }) => {
  const [expanded, setExpanded] = useState(expand);
  const [selected, setSelected] = useState(null);

  const toggle = () => {
    setExpanded(!expanded);
  };

  return (
    <div className="exercise">
      <div className="select-container" onClick={toggle}>
        <div className="select-toggle">
          {
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              color="#444"
            />
          }
        </div>
        <div className="select-value">{exercise.Exercise}</div>
      </div>
      {expanded && (
        <div>
          <p>{exercise.Notes}</p>
          {exercise.Reps && (
            <div>
              <strong>Reps:</strong> {exercise.Reps}
            </div>
          )}
          {exercise.Weight && (
            <div>
              <strong>Weight:</strong> {exercise.Weight}
            </div>
          )}
          {exercise['Time (sec)'] && (
            <div>
              <strong>Time (sec):</strong> {exercise['Time (sec)']}
            </div>
          )}
          {exercise.Rest && (
            <div>
              <strong>Rest:</strong> {exercise.Rest}
            </div>
          )}
          {exercise.Sets && (
            <div>
              <strong>Sets:</strong> {exercise.Sets}
            </div>
          )}
          <br />
          {exercise.Link && (
            <ReactPlayer
              url={exercise.Link}
              className="react-player"
              width="100%"
              height="350px"
              controls={true}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Exercise;
