import { useEffect, useState } from 'react';
import './App.css';
import Select from './components/Select';
import DateSelect from './components/DateSelect';
import Workout from './components/Workout';
const moment = require('moment');

const items = [
  {
    label: 'U15 Sudbury Lady Wolves Summer Program',
    value: 'u15-slw-aa',
  },
];

function App() {
  const [data, setData] = useState();
  const [workout, setWorkout] = useState();

  const load_program = (program) => {
    fetch(`./data/${program}.json`)
      .then((res) => res.json())
      .then((results) => {
        setData(results);
      });
  };

  // const init_today = () => {

  //   if (data !== undefined) {
  //     select_date(new Date());
  //     clearTimeout(timer);
  //   } else {
  //     console.log('firing');
  //     console.log(data);
  //     //timer = setTimeout(init_today, 500);
  //   }
  // };

  const map_links = (exercises, links) => {
    return exercises.map((e) => {
      let link = links[e.Exercise];
      if (link !== undefined) {
        e.Link = link;
      }
      return e;
    });
  };

  const select_date = (date) => {
    let date_lookup = moment(date).format('L');
    let day_key = data.events[date_lookup];

    if (day_key !== undefined) {
      let werkout = data.exercises[day_key];
      let warmup_key = werkout[0].Exercise;
      let cooldown_key = werkout[werkout.length - 1].Exercise;

      let exercises = map_links(werkout, data.links).filter(
        (item) => item.ID !== 'WU' && item.ID !== 'CD'
      );
      let warmups = map_links(data.warmups[warmup_key], data.links);
      let cooldowns = map_links(data.cooldowns[cooldown_key], data.links);

      setWorkout({
        warmups,
        exercises,
        cooldowns,
      });
    } else {
      setWorkout(null);
    }
  };

  useEffect(() => {
    load_program(items[0].value);
  }, []);

  useEffect(() => {
    if (data !== undefined) {
      select_date(new Date());
    }
  }, [data]);

  return (
    <div className="App">
      <Select placeholder="Select program..." items={items} />
      <DateSelect onChange={select_date} events={(data && data.events) || []} />
      <Workout workout={workout} />
    </div>
  );
}

export default App;
