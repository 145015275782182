import Category from './Category';

const Workout = ({ workout }) => {
  return (
    <div className="workout">
      {workout && (
        <>
          <Category name="Warmup" items={workout.warmups} expand={true} />
          <Category name="Workout" items={workout.exercises} />
          <Category name="Cooldown" items={workout.cooldowns} />
        </>
      )}
      {!workout && <div className="rest">Rest day ;)</div>}
    </div>
  );
};

export default Workout;
