import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Select = ({ placeholder = 'Select...', items = [], value, onChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    let item = items.find((item) => item.value === value);
    if (item !== undefined) {
      setSelected(item);
    } else {
      if (items.length === 1) {
        select(items[0]);
      }
    }
  }, [items, value]);

  const toggle = () => {
    items.length > 1 && setExpanded(!expanded);
  };

  const select = (item) => {
    setSelected(item);
    setExpanded(false);
    onChange && onChange(item);
  };

  return (
    <div className="select">
      <div className="select-container" onClick={toggle}>
        <div className="select-value title">
          {selected !== null ? selected.label : placeholder}
        </div>
        <div className="select-toggle">
          {items.length > 1 && (
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              color="#444"
            />
          )}
        </div>
      </div>
      {expanded && (
        <ul className="select-items">
          {items.map((item, i) => {
            return (
              <li key={i} className="select-item" onClick={() => select(item)}>
                {item.label}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Select;
