import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Exercise from './Exercise';

const Category = ({ name = 'Category...', items = [], expand = false }) => {
  const [expanded, setExpanded] = useState(expand);
  const [selected, setSelected] = useState(null);

  const toggle = () => {
    setExpanded(!expanded);
  };

  const select = (item) => {
    setSelected(item);
    //onChange && onChange(item);
  };

  return (
    <div className="select">
      <div className="select-container" onClick={toggle}>
        <div className="select-value">
          {name} ({items.length})
        </div>
        <div className="select-toggle">
          {
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              color="white"
            />
          }
        </div>
      </div>
      {expanded && (
        <ul className="select-items">
          {items.map((item, i) => {
            return (
              <li key={i} className="select-item" onClick={() => select(item)}>
                <Exercise exercise={item} expand={i === 0} />
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Category;
